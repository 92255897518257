/**
 * 判断是否已经授权，如果没有授权的话需要跳转到授权页面
 * * 参数说明：
 * @param redirect_uri - url encode过的页面地址，需要将app_id,agent_id和corp_id等传给后端
 */
import Api from "@/api/api";
import { getQueryVariable } from "@/utils/utils";
const useCheckAuth = (corpId ?: string) => {
  return new Promise(resolve => {
    const need_auth: string =
      process.env.NODE_ENV == "dev" ? "1false" : getQueryVariable("need_auth");
    const href: string = encodeURIComponent(window.location.href);

    const corp_id = corpId || getQueryVariable("corp_id");

    //如果参数上有need_auth=false的话跳过验证
    if (need_auth === "false") {
      resolve("");
      return;
    }

    Api.getAuthCheck({ redirect_uri: href, corp_id: corp_id }).then((res: any) => {
      if(res.code == 500) return
      const { checked, redirect_uri } = res.data;
      if (!checked) {
        window.location.href = redirect_uri;
        resolve("uncheck");
      } else {
        resolve("check");
      }
    });
  });
};
export default useCheckAuth;
