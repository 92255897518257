import HTTP from "@/utils/axios";
import { enterpriseHeaders } from "./headers";
const api = process.env.VUE_APP_API;
const ipv6_api = process.env.VUE_APP_IPV6_API

const Home = {
  //获取数据
  getInfo(params: object) {
    return HTTP.get(`${api}/getInfo`, params);
  },
  getPayPage(params: object) {
    return HTTP.get(`${api}/client/mobile/form/get_page`, params);
  },
  // `${api}/api/website/track`
  websiteTrack(url: any, params: object) {
    return HTTP.formPost(url, params);
  },
  // 短链，短信，智能收集表的中间页数据
  getBlankRedirectPageUrl(params: object) {
    return HTTP.get(`${api}/client/scheme/get`, params);
  },
  // 一键加微的中间页数据接口
  getOneClickUrl(params: object) {
    return HTTP.get(`${api}/client/mobile/button/add_wx`, params);
  },
  getButtonClickUrl(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/link_button/get_structure`, params);
  },
  checkIpv6(params: object) {
    return HTTP.get(`${ipv6_api}/client/mobile/cl_fwd_att`, params);
  },
  getWximUrl(params: object) {
    return HTTP.get(`${api}/client/kf/select`, params);
  },
  getRegisterQrcode(params: object, event_id: string) {
    let code_channel_id = '';
    if(process.env.NODE_ENV == "prd") {
      code_channel_id = '1447521404047200256';
    } else {
      code_channel_id = '1435078304259530752';
    }
    return HTTP.post(`${api}/marketing/webresource/live_code?code_channel_id=${code_channel_id}&event_id=${event_id}`, params);
  },
  postSubmitToApp(params: object) {
    return HTTP.formPost(`${api}/client/mobile/meeting/submit_toapp`, params);
  },
  //检查是否授权
  getAuthCheck(params: object) {
    return HTTP.get(`${api}/wx/auth_check`, params);
  },
  //获取app_id
  getAuthjsTicket(params: object) {
    return HTTP.get(`${api}/wx/authjs_ticket`, params);
  },
  // 会议签到确认
  getMeetingCheck(params: object) {
    return HTTP.get(`${api}/client_v2/mobile/meeting/check`, params, enterpriseHeaders);
  },
  // 名片小程序二维码
  getBizcardCodeInfo(params: object) {
    return HTTP.get(`${api}/interact/bizcard/code_info`, params);
  },
};

export default {
  ...Home
};
