import { getQueryVariable } from "@/utils/utils";

const env: "uat" | "stg" | "prd" = process.env.NODE_ENV as any;
const tokenObj = {
    uat: "auth-token-mp-test",
    stg: "auth-token-mp",
    prd: "auth-token-mp"
};

const token = getQueryVariable("token");

//企业主体headers
export const enterpriseHeaders = token ? {
    [tokenObj[env]]: token
} : {};