import axios from "axios";
import qs from "qs";
import { isEmpty } from "@/utils/utils";
import Vue from "vue";

// const env: "uat" | "stg" | "prd" = process.env.NODE_ENV;
// const tokenObj = {
//   uat: "auth-token-mp-test",
//   stg: "auth-token-mp",
//   prd: "auth-token-mp"
// };
// const token = getQueryVariable("token");

// console.log(process.env, 888);
axios.defaults.baseURL = process.env.VUE_APP_ROOT;
axios.defaults.timeout = 15000;
axios.defaults.withCredentials = true;
axios.defaults.responseType = "json";
// axios.defaults.headers.common[tokenObj[env]] = token;
// axios.defaults.headers.common["Cache-Control"] = "no-cache";
// axios.defaults.headers.get["If-Modified-Since"] = "0";
// http response 拦截器
axios.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error.response.data); // 返回接口返回的错误信息
  }
);

// 在请求拦截器里添加默认参数
// const formatRequestUrl = (url: string) => {
//   if (isEmpty(url)) return "";
//   const tempArr: Array<string> = url.split("?");
//   // //check auth 接口需要corp_id
//   // const corp_id =
//   //   process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
//   //     ? "ww69faddedee10e02b"
//   //     : "ww90b1ab33636e7da5";
//   const app_id =
//     Vue.prototype.app_id ||
//     (process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
//       ? "wx1a4fde609e273d57"
//       : "wx03bbd368069c26b0");

//   if (process.env.NODE_ENV === "dev.local" || process.env.NODE_ENV === "dev") {
//     return url + (tempArr.length <= 1 ? "?" : "&");
//   }

//   return (
//     url +
//     (tempArr.length <= 1 ? "?" : "&") +
//     qs.stringify({
//       // corp_id,
//       app_id // 开发环境注释掉
//       // sid: "2911d9dd10ec5aa52a84607009a80a0d"
//     })
//   );
// };

axios.interceptors.request.use(
  (config: any) => {
    const app_id =
      Vue.prototype.app_id ||
      (process.env.NODE_ENV == "prd" || process.env.NODE_ENV == "stg"
        ? "wx1a4fde609e273d57"
        : "wx03bbd368069c26b0");
    // if (process.env.NODE_ENV !== "dev.local" && process.env.NODE_ENV !== "dev") {
    config.params = Object.assign(
      {
        app_id
      },
      config.params
    );
    // }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

export default class Http {
  static send(config: object) {
    const configs = Object.assign(config);
    return axios(configs)
      .then(res => {
        return res;
      })
      .catch(error => {
        throw error;
      });
  }

  static post(url: string, params = {}, headers = {}, options={}) {
    const config = {
      method: "post",
      url,
      data: params,
      headers,
      ...options
    };
    return Http.send(config);
  }

  static get(url: string, params: any, headers = {}, options={}) {
    let urlParams: any = [];
    Object.keys(params).forEach(key => {
      urlParams.push(`${key}=${encodeURIComponent(params[key])}`);
    });
    if (urlParams.length) {
      urlParams = `${url}?${urlParams.join("&")}`;
    } else {
      urlParams = url;
    }
    const config = {
      data: { unused: 0 },
      headers: { "Content-Type": "application/x-www-form-urlencoded", ...headers },
      url: urlParams,
      params: {
        randomTime: new Date().getTime() // 防止缓存
      },
      ...options
    };
    return Http.send(config);
  }
  static formPost(url: string, params: any = {}, headers = {}, options={}) {
    // const data: any = new FormData();
    // for (const key in params) {
    //   const value = params[key];
    //   data.append(key, value);
    // }
    let data = Object.keys(params)
      .map(function (key) {
        return encodeURIComponent(key) + "=" + encodeURIComponent(params[key]);
      })
      .join("&");
    const config = {
      headers: { "Content-Type": "application/x-www-form-urlencoded", ...headers },
      method: "post",
      url,
      data,
      ...options
    };
    return Http.send(config);
  }
}
