import { getQueryVariable } from "@/utils/utils";
import Api from "@/api/api";

export async function getSignData(corpId?: string) {
    let token = getQueryVariable("token");
    let app_id = getQueryVariable("app_id");
    if (token) return {app_id};
    let corp_id = corpId || getQueryVariable("corp_id");
    if (!corp_id) return undefined;
    return Api.getAuthjsTicket({
        url: window.location.href,
        corp_id,
    }).then((res: any) => {
        const { sign_data } = res.data;
        return sign_data;
    }).catch((e) => {
        return undefined
    });
}
